@import '../../../assets/styles/variables';

.promote-card {
  background-color: map-get($colors, 'primary');
  position: relative;
  z-index: 1;

  &.promote-card-secondary {
    background-color: map-get($colors, 'secondary');
  }

  .promote-card-pattern {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    opacity: 0.05;
    -webkit-mask-image: -webkit-gradient(linear, right bottom, left top, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
  }

  .promote-card-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 3;

    * {
      color: white;
    }

    .promote-card-main {
      flex: 1 1;
      justify-content: center;

      .promote-card-main-label {
        font-size: 2.5rem;
        margin-right: 0.5rem;
      }
    }

    .promote-card-derivative {
      background-color: rgba(0, 0, 0, 0.15);
      border-bottom-right-radius: 0.625rem;
      border-bottom-left-radius: 0.625rem;
      margin: 0 -1.75rem -1rem -1.75rem;
      padding: 1rem 1.75rem;

      .promote-card-derivative-label {
        margin: 0;
      }

      .promote-card-derivative-value {
        opacity: 0.5;
        font-weight: 600;
      }
    }

    .promote-card-pattern {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.10;
    }
  }
}
