@import '../../../../assets/styles/variables';

.text {
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  font-weight: 500;

  &.text-grey {
    color: map-get($greys, 'medium');
  }
}
