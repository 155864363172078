@import '../../../assets/styles/variables';

.chart-card {
  display: flex;
  flex-direction: column;
  position: relative;

  .chart-card-chart-container {
    flex: 1 1;
  }
}
