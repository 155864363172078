@import '../../../assets/styles/variables';

$justify: start, center, end, space-between, space-around;
$align: start, center, end;

$gap: (
    'xs': 1rem,
    's': 2rem,
    'm': 3rem,
    'l': 4rem,
    'xl': 5rem
);

.flex {
  display: flex;

  @each $justifyValue in $justify {
    &.flex-justify-#{$justifyValue} {
      justify-content: $justifyValue;
    }
  }

  @each $alignValue in $align {
    &.flex-align-#{$alignValue} {
      align-items: $alignValue;
    }
  }

  @each $gapName, $gapValue in $gap {
    &.flex-gap-#{$gapName} {
      gap: $gapValue;
    }
  }

  &.flex-horizontal {
    flex-direction: row;
  }

  &.flex-vertical {
    flex-direction: column;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }
}
