@import '../../../../assets/styles/variables';

.title {
  margin: 0;
  padding: 0;
  font-weight: 600;

  &.title-grey {
    color: map-get($greys, 'medium') !important;
  }

  &.title-1 {
    font-size: 1.7rem;
  }

  &.title-2 {
    font-size: 1.5rem;
  }

  &.title-3 {
    font-size: 1.3rem;
  }

  &.title-4 {
    font-size: 1.1rem;
  }

  &.title-5 {
    font-size: 0.9rem;
  }

  &.title-6 {
    font-size: 0.7rem;
  }
}
