@import '../../../assets/styles/variables';

$baseSize: 8rem;
$sizes: (
    xs: 1,
    s: 2,
    m: 3,
    l: 4,
    xl: 5,
    xxl: 6,
);

.card {
  background-color: white;
  border-radius: 0.625rem;
  padding: 1rem 1.75rem;

  @each $size, $sizeValue in $sizes {
    &.card-width-#{$size} {
      min-width: calc(#{$baseSize} * #{$sizeValue});
      width: calc(#{$baseSize} * #{$sizeValue});
    }

    &.card-height-#{$size} {
      min-height: calc(#{$baseSize} * #{$sizeValue});
      height: calc(#{$baseSize} * #{$sizeValue});
    }
  }

  &.card-width-unlimited {
    width: 100%;
  }

  &.card-height-unlimited {
    height: 100%;
  }
}
