@import "./variables";

.flex-1 {
  flex: 1 1;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

@each $i, $spacing in $spacings {
  .p-#{$i} {
    padding: $spacing;
  }
  .py-#{$i} {
    padding-top: $spacing;
    padding-bottom: $spacing;
  }
  .px-#{$i} {
    padding-left: $spacing;
    padding-right: $spacing;
  }
  .pb-#{$i} {
    padding-bottom: $spacing;
  }
  .pt-#{$i} {
    padding-top: $spacing;
  }
  .pl-#{$i} {
    padding-left: $spacing;
  }
  .pr-#{$i} {
    padding-right: $spacing;
  }

  .m-#{$i} {
    margin: $spacing;
  }
  .my-#{$i} {
    margin-top: $spacing;
    margin-bottom: $spacing;
  }
  .mx-#{$i} {
    margin-left: $spacing;
    margin-right: $spacing;
  }
  .mb-#{$i} {
    margin-bottom: $spacing;
  }
  .mt-#{$i} {
    margin-top: $spacing;
  }
  .ml-#{$i} {
    margin-left: $spacing;
  }
  .mr-#{$i} {
    margin-right: $spacing;
  }
}
