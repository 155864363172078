@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import 'antd/dist/antd.min.css';
@import './assets/styles/helpers';
@import './assets/styles/variables';
@import './assets/styles/ant-override';

* {
  font-family: 'Inter', sans-serif;
}

#root {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: map-get($greys, 'light');

  .content {
    flex: 1;

    div[id$="page"] {
      padding: 1.5rem 2.5rem;
    }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.content {
  margin-left: 300px;

  .outlet {
    height: calc(100vh - 60px);
    overflow: auto;
  }
}
