@import '../../../assets/styles/variables';

.login-form {
  display: flex;
  flex-direction: column;

  .login-label {
    margin-bottom: 0.3rem;
    display: inline-block;
  }

  .login-input {
    .ant-input-affix-wrapper {
      padding: 0;
    }

    .ant-form-item-control-input-content {
      padding: 1.2rem;
      border-radius: 0.8rem;
      display: block;
      background-color: #F3F6F9 !important;

      > *, input {
        padding: 0;
        background-color: transparent !important;
        border: none !important;
      }
    }
  }

  .login-submit {
    padding: 1.4rem;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #F3F6F9 inset !important;
  }
}
