$colors: (
    primary: #753D90,
    secondary: #418C97,
    primary-dark: #1F1E2D,
);

$greys: (
    light: #F5F8FA,
    medium: #B5B5C3
);

$spacings: (
    0: 0rem,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.5rem,
    6: 2rem,
    7: 2.5rem,
    8: 3rem,
    9: 3.5rem,
    10: 4rem
);
